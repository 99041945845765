<template>
    <div class="">
        <div class="grid grid-cols-8 gap-4 p-4 border border-gray-400 rounded-3xl shadow-lg">
            <div class="col-span-8 md:col-span-5">
                <video :poster="selectedVideo.link_foto" controls class="object-cover rounded-2xl border border-gray-300 h-96 w-full" controlsList="nodownload">
                    <source :src="selectedVideo.link_video" type="video/mp4" />
                </video>
                <div class="font-bold text-xl mt-4">{{ selectedVideo.judul }}</div>
                <div class="text-primary font-semibold">{{ selectedVideo.tanggal }}</div>
                <div
                    v-html="selectedVideo.isi"
                    class="h-4/6 overflow-auto border-t-2 border-gray-200 font-medium text-justify pr-2 pt-4 mt-2"
                ></div>
            </div>
            <div class="col-span-8 md:col-span-3">
                <div class="border border-gray-200 rounded-2xl shadow-lg">
                    <div class="text-primary text-center my-2 flex flex-row gap-2 justify-center">
                        <button 
                            @click="selectKategori('water')"
                            type="button" 
                            class="font-bold rounded-xl px-2 py-0.5" :class="{'bg-primary text-white': (videoKategori == 'water'), 'text-gray-500 border border-gray-500': (videoKategori != 'water')}">Watersport</button>
                        <button 
                            @click="selectKategori('land')"
                            type="button" 
                            class="font-bold rounded-xl px-2 py-0.5" :class="{'bg-primary text-white': (videoKategori == 'land'), 'text-gray-500 border border-gray-500': (videoKategori != 'land')}">Land Adventure</button>
                        <button 
                            @click="selectKategori('sky')"
                            type="button" 
                            class="font-bold rounded-xl px-2 py-0.5" :class="{'bg-primary text-white': (videoKategori == 'sky'), 'text-gray-500 border border-gray-500': (videoKategori != 'sky')}">Sky Adventure</button>
                    </div>
                    <div class="h-70vh overflow-auto px-4 pb-4 overflow-y-auto">
                        <div
                            v-for="video in videos"
                            :key="video.id"
                            class="overflow-hidden cursor-pointer hover:bg-gray-100" :class="{'bg-gray-100': selectedVideo.id == video.id}"
                            @click="openDetailPage(video)"
                        >
                            <div class="grid grid-cols-5 p-2 gap-2">
                                <div class="col-span-2">
                                    <div class="py-2 px-4 w-full h-20 border border-gray-300 grid items-center" :style="{
                                        backgroundImage: 'url(\''+video.link_foto+'\')',
                                        backgroundSize: 'cover',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundPosition: 'center center'
                                    }">
                                    <div class="text-3xl text-white grid justify-items-center">
                                    <font-awesome-icon icon="fa-regular fa-circle-play" />
                                    </div>
                                    </div>
                                </div>
                                <div class="col-span-3 pt-2 pr-2 grid items-center">
                                    <div class="text-sm font-semibold">{{ video.judul }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { Slugify } from '@/utils';
    export default {
        name: 'VideoDetail',

        data() {
            return {
                selectedVideo: {},
                videos: [],
                videoKategori: "water",
            };
        },

        computed: {
        },

        beforeRouteEnter(to, from, next) {
            if (to.params.video === undefined) {
                next({ name: 'VideoIndex' });
            } else {
                next();
            }
        },

        async created() {
            this.$store.commit('setOverlayLoading', true);

            this.selectedVideo = this.$route.params.video;

            this.$store.commit('setBreadcrumbItems', [
                { text: 'Home', routeName: 'Home' },
                { text: 'Video', routeName: 'VideoIndex' },
                { text: this.selectedVideo.judul, routeName: 'VideoDetail' }
            ]);

            await this.getVideos();

            this.$store.commit('setOverlayLoading', false);
        },

        methods: {
            selectKategori(newCat){
                this.videoKategori = newCat;
                this.getVideos();
            },
            async getVideos() {
                var bodyFormData = new FormData();
                bodyFormData.append('txtKate', this.videoKategori);
                this.$store.commit('setOverlayLoading', true);

                try {
                    const response = await this.$http.post(`${this.$apiTripwe}/ic/jetski-android-new/list_video_v15.php`, bodyFormData);
                    
                    this.videos = response.data;
                } catch (error) {
                    this.$toasted.global.error(error);
                }

                this.$store.commit('setOverlayLoading', false);
            },
            openDetailPage(video) {
                this.selectedVideo = video;
                this.$router.replace({
                    name: 'VideoDetail',
                    params: { slug: Slugify(video.judul), video }
                });
            }
        }
    };
</script>
